
import {request} from "@/network/request.js";
import axios from 'axios'
import qs from 'qs';

export default{
  // 按照单据分页查询单据信息
  findPurchaseBillsReport(data){
    return request({
      method:'POST',
      url:'/report/purchase/findPurchaseBillsReport',
      data
    })
  },
  //查询-区域
  findArea(params){
    return axios({
      method: "get",
      url:'https://plugin.yingyanso.net/area',
      params
    })
  },
  //按照分页产品查询采购信息
  findPurchaseProductReport(data){
    return request({
      method:'POST',
      url:'/report/purchase/findPurchaseProductReport',
      data
    })
  },
  // 按照供应商分页查询采购信息
  findPurchaseSupplierReport(data){
    return request({
      method:'POST',
      url:'/report/purchase/findPurchaseSupplierReport',
      data
    })
  },
  //查询-条件查询采购汇总信息
  findPurchaseReport(data){
    return request({
      method:'POST',
      url:'/report/purchase/findPurchaseReport',
      data
    })
  },
  // 查询-商品类别
  findAllProductTypeNoChild(data){
    return request({
      method:'POST',
      url:'/system/dictionary/findAllProductTypeNoChild',
      data
    })
  },
  //查询-采购单据类型
  findByTypeId(data){
    return request({
      method:'POST',
      url:'/system/findByTypeId',
      data
    })
  },
}