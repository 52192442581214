<!--采购汇总表-->
<template>
  <div class="statement_box">
    <div 
      v-loading="dateLoading" 
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="statement_top_date">
      <div class="statement_date_query">
        <div class="statement_date_query_left">
            <!--年-->
          <el-select v-model="yearValue" placeholder="年" @change="handleDateChange('year')">
            <el-option
              v-for="item in yearOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--季度-->
          <el-select v-model="quarterValue" placeholder="季" @change="handleDateChange('quarter')">
            <el-option
              v-for="item in quarterOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--月-->
          <el-select v-model="monthValue" placeholder="月" @change="handleDateChange('month')">
            <el-option
              v-for="item in monthOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!--天-->
          <el-select v-model="dayValue" placeholder="日" @change="handleDateChange('day')">
            <el-option
              v-for="item in dayOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" :plain="isAll" @click="handleSearchBtn">全部</el-button>
          <el-date-picker
            class="date_range"
            v-model="pickerValue"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="onDatePickerChange">
          </el-date-picker>
        </div>
        <div class="statement_date_query_right">
          <div class="statement_explain">统计说明<i class="el-icon-question"></i></div>
          <el-button plain size="small" @click="exportStatement">导出</el-button>
        </div>
      </div>
      <div class="statement_date_content">
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">采购金额</div>
            <div class="statement_date_val">{{divisionMoney(collectData.purchaseMoney)}}</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_label">采购数量</div>
            <div class="statement_date_val">{{divisionMoney(collectData.purchaseCount)}}</div>
          </div>
        </div>
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">采购单数</div>
            <div class="statement_date_val">{{divisionMoney(collectData.purchaseNumber)}}</div>
          </div>
          <div class="statement_date_content_col">
            <div class="statement_date_label">采购商</div>
            <div class="statement_date_val">{{divisionMoney(collectData.supplierCount)}}</div>
          </div>
        </div>
        <div class="statement_date_content_row">
          <div class="statement_date_content_col">
            <div class="statement_date_label">待付款</div>
            <div class="statement_date_val">{{divisionMoney(collectData.awaitMoney)}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="statement_chart_box">
      <div>
        <el-radio-group v-model="summaryType" size="small" @change="onSummaryTypeChange">
          <el-radio-button :label="1">按产品</el-radio-button>
          <el-radio-button :label="2">按供应商</el-radio-button>
          <el-radio-button :label="3">按单据</el-radio-button>
        </el-radio-group>
      </div>
      <div class="statement_chart_query">
        <div v-if="summaryType === 1" class="statement_chart_query_row">
          <el-select v-model="productValue" placeholder="商品类别" class="statement_chart_query_col mr10" size="small">
            <el-option
              v-for="item in productOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-input class="statement_chart_query_col mr10" placeholder="商品编号/名称/规格" v-model="productName" size="small"></el-input>
          <el-button type="primary" size="small" @click="handleChartChange">查询</el-button>
          <el-button type="primary" size="small" @click="handleReset">重置</el-button>
        </div>
        <div v-else-if="summaryType === 2" class="statement_chart_query_row">
          <el-select v-model="countryValue" placeholder="客户区域" class="statement_chart_query_col mr10" size="small" @change="getAreaData(1)">
            <el-option
              v-for="item in countryOptions"
              :key="item.id"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-if="provinceOptions && provinceOptions.length" v-model="provinceValue" placeholder="客户区域" class="statement_chart_query_col mr10" size="small" @change="getAreaData(2)">
            <el-option
              v-for="item in provinceOptions"
              :key="item.id"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-if="cityOptions && cityOptions.length" v-model="cityValue" placeholder="客户区域" class="statement_chart_query_col mr10" size="small">
            <el-option
              v-for="item in cityOptions"
              :key="item.id"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
          <el-input class="statement_chart_query_col mr10" placeholder="客户名称" v-model="clientName" size="small"></el-input>
          <el-button type="primary" size="small" @click="handleChartChange">查询</el-button>
          <el-button type="primary" size="small" @click="handleReset">重置</el-button>
        </div>
        <div v-else class="statement_chart_query_row">
          <el-select v-model="billsTypeId" placeholder="单据分类" class="statement_chart_query_col mr10" size="small">
            <el-option
              v-for="item in byTypeOptions"
              :key="item.id"
              :label="item.bills_type"
              :value="item.id">
            </el-option>
          </el-select>
          <el-input class="statement_chart_query_col mr10" placeholder="品牌名称" v-model="brandName" size="small"></el-input>
          <el-button type="primary" size="small" @click="handleChartChange">查询</el-button>
          <el-button type="primary" size="small" @click="handleReset">重置</el-button>
        </div>
        <div class="statement_chart_dec">
          <div class="statement_chart_sum">
            {{divisionMoney(collectData.beforePurchaseMoney)}}
          </div>
          <div class="statement_chart_text">
            <div>---</div>
            <div>相比上周期</div>
          </div>
        </div>
      </div>
      <div class="statement_chart_content" id="achievements_chart"></div>
    </div>
    <div class="statement_table_box">
      <div class="statement_table_title">{{summaryType === 1 ? "按产品" : summaryType === 2 ? "按供应商" : "按单据"}}</div>
      <el-table
        v-show="(summaryType === 1)"
        :data="tableData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="productName"
          label="商品名称/货品类别">
          <template slot-scope="scope">
            {{scope.row.productName + '/' + scope.row.typeName}}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseNum"
          label="采购数量">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseCount"
          label="采购笔数">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseAmount"
          label="采购金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="refundAmount"
          label="退货金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="refundNum"
          label="退货数量">
        </el-table-column>
        <el-table-column
        show-overflow-tooltip
        prop="otherAmount"
        label="其它费用">
        </el-table-column>
        <el-table-column
        show-overflow-tooltip
        prop="costPriceAmount"
        label="成本金额">
        </el-table-column>
      </el-table>
      <el-table
        v-show="(summaryType === 2)"
        :data="tableData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="supplierName"
          label="供应商名称/区域">
          <template slot-scope="scope">
            {{scope.row.supplierName + '/' + scope.row.province}}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseNum"
          label="采购数量">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseCount"
          label="采购笔数">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseAmount"
          label="采购金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="refundAmount"
          label="退货金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="refundNum"
          label="退货数量">
        </el-table-column>
        <el-table-column
        show-overflow-tooltip
        prop="otherAmount"
        label="其它费用">
        </el-table-column>
        <el-table-column
        show-overflow-tooltip
        prop="costPriceAmount"
        label="成本金额">
        </el-table-column>
      </el-table>
      <el-table
        v-show="(summaryType === 3)"
        :data="tableData"
        size="mini"
        style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="billsNo"
          label="单据编号">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="countTime"
          label="单据日期">
          <template slot-scope="scope">
            {{getSingleDate(scope.row.countTime)}}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="supplierName"
          label="供应商名称">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseNum"
          label="采购数量">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseAmount"
          label="采购金额">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="refundAmount"
          label="退货金额">
        </el-table-column>
        <el-table-column
        show-overflow-tooltip
        prop="otherAmount"
        label="其它费用">
        </el-table-column>
        <el-table-column
        show-overflow-tooltip
        prop="costPriceAmount"
        label="成本金额">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        prev-text="上一页"
        next-text="下一页"
        :current-page="paginationOptions.page"
        :page-size="paginationOptions.size"
        :total="paginationOptions.total"
        @current-change="handleCurrentChange">
    </el-pagination>
    </div>
  </div>
</template>

<script>
  import purchaseApi from '@/network/statement/repertory/purchase/index.js';
  import {parseTime} from '@/util/index.js';

  export default{
    data() {
      return{
        dateLoading: false,
        yearOptions: "",
        quarterOptions: "",
        monthOptions: "",
        dayOptions: "",
        yearValue: "",
        quarterValue: "",
        monthValue: "",
        dayValue: "",
        isAll: true,
        pickerValue: "",
        collectData: "",
        summaryType: 1,
        countryOptions: "",
        countryValue: "",
        provinceOptions: "",
        provinceValue: "",
        cityOptions: "",
        cityValue: "",
        clientName: "",
        productOptions: '',
        productValue: '',
        productName: '',
        byTypeOptions: '',
        billsTypeId: '',
        brandName: "",
        chartData: [],
        tableData: [],
        paginationOptions: {
          page: 1,
          size: 10,
          total: 0
        },
      }
    },
    mounted() {
      this.getTimeist()
      this.getProductTypeData()
      this.getAreaData()
      this.getByTypeData()
    },
    methods: {
      // 获取年季月
      getTimeist(){
        let t = new Date();
        let y = t.getFullYear()
        let m = t.getMonth()
        let q = parseInt(m/3) + 1
        // 获取本年本季本月
        this.yearValue = y
        this.quarterValue = q
        this.monthValue = m + 1
        // 设置下拉框展示的年
        let maxY = 10, yArr = []
        // 年
        for(let i = 0; i < maxY; i++){
          yArr.push({
            label: (y - i) + "年",
            value: y - i
          })
        }
        this.yearOptions = yArr
        // 季
        this.getQuarterList()
        // 月
        this.getMonthList(q)
        // 天
        this.getDayList(y, m)
        // 获取数据
        this.getList()
        this.getChartList()
        this.getCollectData()
      },
      // 获取季度
      getQuarterList(){
        // 季
        let qArr = []
        qArr.push({
          label: "全部",
          value: null
        })
        for(let i = 1; i <= 4; i++){
          qArr.push({
            label: "第" + i + "季度",
            value: i
          })
        }
        this.quarterOptions = qArr
      },
      // 根据季度获取月份
      getMonthList(q){
        let minM = q*3 - 2
        let maxM = q*3
        let mArr = []
        for(let i = minM; i <= maxM; i++){
          mArr.push({
            label: i + "月",
            value: i
          })
        }
        this.monthOptions = mArr
      },
      // 获取选中月份的天数
      getDayList(y,m){
        let maxD = new Date(y, m, 0).getDate()
        if(this.dayValue > maxD){
          this.dayValue = 1
        }
        let dArr = []
        for(let i = 1; i <= maxD; i++){
          dArr.push({
            label: i + "号",
            value: i
          })
        }
        this.dayOptions = dArr
      },
      // 监听下拉时间变动
      handleDateChange(type){
        this.isAll = true
        this.pickerValue = ""
        if(type === "year"){
          this.monthOptions = ""
          this.dayOptions = ""
          this.quarterValue = ""
          this.monthValue = ""
          this.dayValue = ""
          this.getQuarterList()
        }else if(type === "quarter"){
          this.dayOptions = ""
          this.monthValue = ""
          this.dayValue = ""
          this.getMonthList(this.quarterValue)
        }else if(type === "month"){
          this.dayValue = ""
          this.getDayList(this.yearValue, this.monthValue)
        }
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 查询全部
      handleSearchBtn(){
        this.pickerValue=""
        this.quarterOptions = ""
        this.monthOptions = ""
        this.dayOptions = ""
        this.yearValue = ""
        this.quarterValue = ""
        this.monthValue = ""
        this.dayValue = ""
        this.isAll = false
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // 按字段时间范围查询
      onDatePickerChange(){
        this.quarterOptions = ""
        this.monthOptions = ""
        this.dayOptions = ""
        this.yearValue = ""
        this.quarterValue = ""
        this.monthValue = ""
        this.dayValue = ""
        this.isAll = this.pickerValue ? true : false
        this.getList()
        this.getCollectData()
        this.getChartList()
      },
      // tab切换
      onSummaryTypeChange(){
        // 按产品
        this.productValue = ""
        this.productName = ""
        // 按供应商
        this.countryValue = ""
        this.provinceOptions = ""
        this.provinceValue = ""
        this.cityOptions = ""
        this.cityValue = ""
        this.clientName = ""
        // 按单据
        this.billsTypeId = ""
        this.brandName = ""
        this.paginationOptions = {
          page: 1,
          size: 10,
          total: 0
        }
        this.getList()
        this.getChartList()
      },
      // 获取商品类别
      getProductTypeData(){
        purchaseApi.findAllProductTypeNoChild({
          typeId: 2
        }).then((res)=>{
          if(res.code == 200){
            this.productOptions = res.data || ""
          }
        })
      }, 
      // 获取区域
      getAreaData(type){
        let params = ""
        if(type === 1){
          let country = JSON.parse(this.countryValue)
          params = {
            type,
            company_short: country.short
          }
          this.provinceOptions = ""
          this.provinceValue = ""
          this.cityOptions = ""
          this.cityValue = ""
        }else if(type === 2){
          let province = JSON.parse(this.provinceValue)
          params = {
            type,
            province_short: province.short
          }
          this.cityOptions = ""
          this.cityValue = ""
        }
        purchaseApi.findArea(params).then(res=>{
          if(res.data.code == 200){
            if(type === 1){
              this.provinceOptions = res.data.data.map(item=>({
                ...item,
                value: JSON.stringify(item)
              }))
            }else if(type === 2){
              this.cityOptions = res.data.data.map(item=>({
                ...item,
                value: JSON.stringify(item)
              }))
            }else{
              this.countryOptions = res.data.data.map(item=>({
                ...item,
                value: JSON.stringify(item)
              }))
            }
          }
        })
      }, 
      // 获取单据分类
      getByTypeData(){
        purchaseApi.findByTypeId({
          typeId: 2
        }).then((res)=>{
          if(res.code == 200){
            this.byTypeOptions = res.data || ""
          }
        })
      }, 
      // 获取商品出入库汇总信息
      getCollectData(){
        const [startTime, endTime] = this.pickerValue
        purchaseApi.findPurchaseReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime
        }).then((res)=>{
          if(res.code == 200){
            this.collectData = res.data || ""
          }
        })
      },
      // 获取按产品数据
      getProductList(){
        const [startTime, endTime] = this.pickerValue
        purchaseApi.findPurchaseProductReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          productTypeId: this.productValue,
          search: this.productName,
          pageIndex: this.paginationOptions.page,
          pageSize: this.paginationOptions.size,
        }).then((res)=>{
          if(res.code == 200){
            this.tableData = res.data.rows
            this.paginationOptions = {...this.paginationOptions,total:res.data.total}
          }
        })
      },
      // 获取按供应商数据
      getSupplierList(){
        const [startTime, endTime] = this.pickerValue
        purchaseApi.findPurchaseSupplierReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          country: this.countryValue ? JSON.parse(this.countryValue).name : "",
          province: this.provinceValue ? JSON.parse(this.provinceValue).name : "",
          city: this.cityValue ? JSON.parse(this.cityValue).name : "",
          search: this.clientName,
          pageIndex: this.paginationOptions.page,
          pageSize: this.paginationOptions.size,
        }).then((res)=>{
          if(res.code == 200){
            this.tableData = res.data.rows
            this.paginationOptions = {...this.paginationOptions,total:res.data.total}
          }
        })
      },
      // 获取按单据数据
      getBillsList(){
        const [startTime, endTime] = this.pickerValue
        purchaseApi.findPurchaseBillsReport({
          year: this.yearValue,
          quarter: this.quarterValue,
          month: this.monthValue,
          day: this.dayValue,
          startTime,
          endTime,
          billsTypeId: this.billsTypeId,
          search: this.brandName,
          pageIndex: this.paginationOptions.page,
          pageSize: this.paginationOptions.size,
        }).then((res)=>{
          if(res.code == 200){
            this.tableData = res.data.rows
            this.paginationOptions = {...this.paginationOptions,total:res.data.total}
          }
        })
      },
      getList(){
        if(this.summaryType === 1){
          this.getProductList()
        }else if(this.summaryType === 2){
          this.getSupplierList()
        }else{
          this.getBillsList()
        }
      },
      // 获取图表数据
      getChartList(){
        const [startTime, endTime] = this.pickerValue
        if(this.summaryType === 1){
          purchaseApi.findPurchaseProductReport({
            year: this.yearValue,
            quarter: this.quarterValue,
            month: this.monthValue,
            day: this.dayValue,
            startTime,
            endTime,
            productTypeId: this.productValue,
            search: this.productName,
            pageIndex: 1,
            pageSize: 20,
          }).then((res)=>{
            if(res.code == 200){
              this.chartData = res.data.rows
              this.graphing()
            }
          })
        }else if(this.summaryType === 2){
          purchaseApi.findPurchaseSupplierReport({
            year: this.yearValue,
            quarter: this.quarterValue,
            month: this.monthValue,
            day: this.dayValue,
            startTime,
            endTime,
            country: this.countryValue ? JSON.parse(this.countryValue).name : "",
            province: this.provinceValue ? JSON.parse(this.provinceValue).name : "",
            city: this.cityValue ? JSON.parse(this.cityValue).name : "",
            search: this.clientName,
            pageIndex: 1,
            pageSize: 20,
          }).then((res)=>{
            if(res.code == 200){
              this.chartData = res.data.rows
              this.graphing()
            }
          })
        }else if(this.summaryType === 3){
          purchaseApi.findPurchaseBillsReport({
            year: this.yearValue,
            quarter: this.quarterValue,
            month: this.monthValue,
            day: this.dayValue,
            startTime,
            endTime,
            billsTypeId: this.billsTypeId,
            search: this.brandName,
            pageIndex: 1,
            pageSize: 20,
          }).then((res)=>{
            if(res.code == 200){
              this.chartData = res.data.rows
              this.graphing()
            }
          })
        }
      },
       // 表格数据
      _formatJson(filterVal, jsonData){
        let data = jsonData.map(row => filterVal.map(j => {
          let value = ''
          if(j === "countTime"){
            value = this.getSingleDate(row[j])
          }else{
            value = row[j]
          }
          return value
        }))
        return data
      },
      // 导出
      exportStatement(){
        const [startTime, endTime] = this.pickerValue
        if(this.summaryType === 1){
          purchaseApi.findPurchaseProductReport({
            year: this.yearValue,
            quarter: this.quarterValue,
            month: this.monthValue,
            day: this.dayValue,
            startTime,
            endTime,
            productTypeId: this.productValue,
            search: this.productName,
            pageIndex: 1,
            pageSize: 10000,
          }).then((res)=>{
            if(res.code == 200){
              const excelObj = {
                productName: "商品名称",
                typeName: "货品类别",
                purchaseNum: "采购数量",
                purchaseCount: "采购笔数",
                purchaseAmount: "采购金额",
                refundAmount: "退货金额",
                refundNum: "退货数量",
                otherAmount: "其它费用",
                costPriceAmount: "成本金额",
              }
              this.exportXlsx(excelObj, res.data.rows)
            }
          })
        }else if(this.summaryType === 2){
          purchaseApi.findPurchaseSupplierReport({
            year: this.yearValue,
            quarter: this.quarterValue,
            month: this.monthValue,
            day: this.dayValue,
            startTime,
            endTime,
            country: this.countryValue ? JSON.parse(this.countryValue).name : "",
            province: this.provinceValue ? JSON.parse(this.provinceValue).name : "",
            city: this.cityValue ? JSON.parse(this.cityValue).name : "",
            search: this.clientName,
            pageIndex: 1,
            pageSize: 10000,
          }).then((res)=>{
            if(res.code == 200){
              const excelObj = {
                supplierName: "供应商名称",
                province: "区域",
                purchaseNum: "采购数量",
                purchaseCount: "采购笔数",
                purchaseAmount: "采购金额",
                refundAmount: "退货金额",
                refundNum: "退货数量",
                otherAmount: "其它费用",
                costPriceAmount: "成本金额",
              }
              this.exportXlsx(excelObj, res.data.rows)
            }
          })
        }else{
          purchaseApi.findPurchaseBillsReport({
            year: this.yearValue,
            quarter: this.quarterValue,
            month: this.monthValue,
            day: this.dayValue,
            startTime,
            endTime,
            billsTypeId: this.billsTypeId,
            search: this.brandName,
            pageIndex: 1,
            pageSize: 10000,
          }).then((res)=>{
            if(res.code == 200){
              const excelObj = {
                billsNo: "单据编号",
                countTime: "单据日期",
                supplierName: "供应商名称",
                purchaseNum: "采购数量",
                purchaseAmount: "采购金额",
                refundAmount: "退货金额",
                otherAmount: "其它费用",
                costPriceAmount: "成本金额",
              }
              this.exportXlsx(excelObj, res.data.rows)
            }
          })
        }
      },
      exportXlsx(excelObj, rows){
        import('@/util/Export2Excel').then(excel => {
          const data = this._formatJson(Object.keys(excelObj), rows)
          excel.export_json_to_excel({
            header: Object.values(excelObj),
            data,
            filename: '采购汇总表',
            autoWidth: true,
            bookType: 'xlsx',
          })
        })
      },
      // 金额展示添加逗号处理
      divisionMoney(val){
        if(val){
          let str = (val + '').split('.')
          let intSum = str[0].replace(/\B(?=(?:\d{3})+$)/g, ',')
          let dot = str[1] ? '.' + str[1] : ''
          let num = intSum + dot
          return num
        }else{
          return 0
        }
      },
      // 查询图标数据
      handleChartChange(){
        this.graphing()
        this.getList()
        this.getChartList()
      },
      // 重置
      handleReset(){
        // 按产品
        this.productValue = ""
        this.productName = ""
        // 按供应商
        this.countryValue = ""
        this.provinceOptions = ""
        this.provinceValue = ""
        this.cityOptions = ""
        this.cityValue = ""
        this.clientName = ""
        // 按单据
        this.billsTypeId = ""
        this.brandName = ""
      },
      // 获取x轴数组
      getXName(){
        let axisArr = []
        if(this.summaryType === 1){
          this.chartData.map(item=>{
            axisArr.push(item.productName)
          })
        }else if(this.summaryType === 2){
          this.chartData.map(item=>{
            axisArr.push(item.supplierName)
          })
        }else{
          this.chartData.map(item=>{
            axisArr.push(item.billsNo)
          })
        }
        return axisArr
      },
      // 获取y轴数据
      getYData(){
        let axisData = []
        if(this.summaryType === 1){
          for(let i = 0; i < 3; i++){
            // 采购数量/采购金额/采购笔数
            let axisArr = []
            this.chartData.map(item=>{
              switch(i){
                case 0:
                  axisArr.push(item.purchaseNum)
                  break;
                case 1:
                  axisArr.push(item.purchaseAmount)
                  break;
                case 2:
                  axisArr.push(item.purchaseCount)
                  break;
              }
              
            })
            axisData.push({
              name: i === 0 ? '采购数量' : i === 1 ? '采购金额' : '采购笔数',
              type: 'bar',
              data: axisArr,
              animation:true,
            })
          }
        }else if(this.summaryType === 2){
          for(let i = 0; i < 3; i++){
            // 采购数量/采购金额/采购笔数
            let axisArr = []
            this.chartData.map(item=>{
              switch(i){
                case 0:
                  axisArr.push(item.purchaseNum)
                  break;
                case 1:
                  axisArr.push(item.purchaseAmount)
                  break;
                case 2:
                  axisArr.push(item.purchaseCount)
                  break;
              }
              
            })
            axisData.push({
              name: i === 0 ? '采购数量' : i === 1 ? '采购金额' : '采购笔数',
              type: 'bar',
              data: axisArr,
              animation:true,
            })
          }
        }else{
          for(let i = 0; i < 2; i++){
            // 采购数量/采购金额
            let axisArr = []
            this.chartData.map(item=>{
              switch(i){
                case 0:
                  axisArr.push(item.purchaseNum)
                  break;
                case 1:
                  axisArr.push(item.purchaseAmount)
                  break;
              }
              
            })
            axisData.push({
              name: i === 0 ? '采购数量' : '采购金额',
              type: 'bar',
              data: axisArr,
              animation:true,
            })
          }
        }
        return axisData
      },
      getLegend(){
        if(this.summaryType === 1){
          return ['采购数量', '采购金额', '采购笔数']
        }else if(this.summaryType === 2){
          return ['采购数量', '采购金额', '采购笔数']
        }else{
          return ['采购数量', '采购金额']
        }
      },
      // 生成图表
      graphing(){
        let xAxisData = this.getXName()
        let yAxisData = this.getYData()
        let legendData = this.getLegend()
        let myChart = this.$echarts.init(document.getElementById('achievements_chart'))
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: legendData
          },
          grid: {
            left: 40,
            right: 10,
            bottom: 0,
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xAxisData,
              axisTick: {
                alignWithLabel: true,
                show: false
              },
              axisLabel: {
                rotate: 70,
                color: "#000000"
              },
              axisLine:{
                lineStyle: {
                  type: 'dashed',
                  color: '#cccccc',
                }
              },
              minorSplitLine : {
                show: true,
                lineStyle:{
                  type: 'dashed'
                }
              },
              splitLine: {
                show: true,
                lineStyle:{
                  type: 'dashed'
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisTick: {
                alignWithLabel: true,
                show: false,
              },
              axisLabel: {
                color: "#000000"
              },
              axisLine:{
                lineStyle: {
                  type: 'dashed',
                  color: '#cccccc'
                }
              },
              splitLine: {
                lineStyle:{
                  type: 'dashed'
                }
              }
            }
          ],
          color: ['#0091ff', '#5470c6', '#91cc75', '#ee6666', '#73c0de'],
          series: yAxisData
        }
        myChart.setOption(option);
      },
      // 点击页数
      handleCurrentChange(e){
        this.paginationOptions = {...this.paginationOptions, page: e}
        this.getList()
      },
      //获取年月日
      getSingleDate(str) {
        return parseTime(str, '{y}-{m}-{d}')
      }
    }
  }
</script>

<style lang="less">
</style>
